import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useDocumentEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const document = ref({})
  const isSubmitting = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'document'

  const show = async () => {
    isSubmitting.value = true

    try {
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

      document.value = item
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.document_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async data => {
    isSubmitting.value = true

    const payload = {
      name: data.name?.value || '',
    }

    try {
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.form_success'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.document_updated'))
    } finally {
      router.push({ name: 'organization-document-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    document,
    isSubmitting,
  }
}
